import React from 'react';
import PropTypes from "prop-types"
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  underline: {
    display: 'inline-block',
    position: 'relative',
    marginBottom: 30,
    letterSpacing: 5
  },
  underlineColorGreen: {
    '&:after': {
      position: 'absolute',
      content: "''",
      height: 4,
      bottom: -30,
      width: 60,
      background: theme.palette.green[900],
    },
  },
  underlineColorRed: {
    '&:after': {
      position: 'absolute',
      content: "''",
      height: 4,
      bottom: -30,
      width: 60,
      background: theme.palette.text.secondary,
    },
  },
  underlineColorBlue: {
    '&:after': {
      position: 'absolute',
      content: "''",
      height: 4,
      bottom: -30,
      width: 60,
      background: theme.palette.blue[900],
    },
  },
  fontColorDefault: {
    color: theme.palette.text.primary
  },
  fontColorWhite: {
    color: theme.palette.default.main
  },
  underlineCenter: {
    '&:after': {
      left: 0,
      right: 0,
      margin: '0 auto'
    }
  },
  underlineRight: {
    '&:after': {
      right: 0,
    }
  },
  underlineLeft: {
    '&:after': {
      left: 0,
    }
  },
  uppercase: {
    textTransform: 'uppercase'
  }
}));

const UnderlinedTitle = ({
  variant,
  title,
  align,
  uppercase,
  color,
  fontColor,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant={variant}
      className={clsx(
        classes.underline, align === 'center' ?
        classes.underlineCenter : align === 'right' ?
          classes.underlineRight : classes.underlineLeft, 
        uppercase && classes.uppercase,
        color === 'green' ? classes.underlineColorGreen : color === 'red' ? classes.underlineColorRed : classes.underlineColorBlue,
        fontColor === 'white' ? classes.fontColorWhite : classes.fontColorDefault
      )}
      align={align}
      {...rest}
    >
      {title}
    </Typography>
  );
}

UnderlinedTitle.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  uppercase: PropTypes.bool,
  align: PropTypes.string,
  color: PropTypes.string,
}

UnderlinedTitle.defaultProps = {
  variant: ``,
  title: ``,
  uppercase: false,
  align: `left`,
  color: 'red',
}

export default UnderlinedTitle

