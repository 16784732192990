import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography'
import UnderlinedTitle from "../underlinedTitle"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt,
  faEnvelope,
  faHeadphones
} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.grey[850],
    paddingTop: 70,
    paddingBottom: 70,
  },
  footerTitle: {
    marginBottom: 35
  },
  icon: {
    fontSize: 30,
    color: theme.palette.default.main,
    marginRight: 25
  },
  getInTouch: {
    paddingTop: 20,
    paddingBottom: 20
  },
  item: {
    fontSize: 20,
    color: theme.palette.default.main,
  },
  subscribeInput: {
    borderRadius: 0,
    color: theme.palette.default.main,

    '&.MuiInputBase-root': {
      color: '#fff'
    }
  },
  notchedOutline: {
    borderColor: theme.palette.blue[900],
  },
  input: {
    padding: '13.5px 14px'
  },

}));

const Footer = ({
  data
}) => {
  const classes = useStyles();
  const [subscribe, setSubscribe] = React.useState('');

  const handleChange = (event) => {
    setSubscribe(event.target.value);
  };

  return (
    <footer
      className={classes.footer}
    >
      <Container>
        <UnderlinedTitle
          variant="h3"
          title="Get in touch"
          color="blue"
          uppercase={true}
          fontColor="white"
        />
        <Grid
          container
          spacing={5}
          className={classes.getInTouch}
        >
          <Grid
            item
            md={4}
            sm={12}
          >
            <Box
              display="flex"
              flexDirection="row"
            >
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className={classes.icon}
              />
              <Typography
                variant="h6"
                component="p"
                className={classes.item}
              >
                {data.address}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
          >
            <Box
              display="flex"
              flexDirection="row"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className={classes.icon}
              />
              <Typography
                variant="h6"
                component="p"
                className={classes.item}
              >
                {data.email}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
          >
            <Box
              display="flex"
              flexDirection="row"
            >
              <FontAwesomeIcon
                icon={faHeadphones}
                className={classes.icon}
              />
              <Typography
                variant="h6"
                component="p"
                className={classes.item}
              >
                {data.phoneNumber}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <UnderlinedTitle
          variant="h3"
          title="Subscribe"
          color="blue"
          uppercase={true}
          fontColor="white"
        />
        <Box
          py={3}
        >
          <Grid
            container
            spacing={5}
          >
            <Grid
              item
              md={4}
              sm={12}
            >
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.item}
                >
                  Subscribe to our mailing list to get 
                  the updates to your email inbox.
                </Typography>
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
              <FormControl 
                variant="outlined"
                fullWidth
              >
                <InputLabel 
                  htmlFor="outlined-email"
                >
                  
                </InputLabel>
                <OutlinedInput
                  id="outlined-email"
                  value={subscribe}
                  onChange={handleChange}
                  aria-describedby="outlined-email"
                  labelWidth={0}
                  classes={{
                    root: classes.subscribeInput,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input
                  }}
                  inputProps={{ 'aria-label': 'subscribe' }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </footer>
  )
}

export default Footer