import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
        'Avenir Next LT Pro, sans-serif',
      ].join(','),
      h1: {
        fontSize: 62,
        fontWeight: 600,
      },
      h2: {
        fontSize: 35,
        fontWeight: 600,
      },
      h3: {
        fontSize: 25,
        fontWeight: 600,
      },
      h4: {
        fontSize: "1rem",
        fontWeight: 600,
      },
      body2: {
      },
      subtitle1: {
        fontWeight: 600,
      },
      button: {
        // fontWeight: 500
        // fontSize: "0.875rem"
        // lineHeight: 1.75
        // letterSpacing: "0.02857em"
        // textTransform: "uppercase"
      }
    },
    palette: {
      default: {
        main: '#fff',
      },
      primary: {
        main: '#fab702',
        dark: '#fca602',
        contrastText: '#272727'
      },
      secondary: {
        main: '#fff',
        contrastText: '#fff',
        dark: '#111111'
      },
      text: {
        primary: '#000000',
        secondary: '#ff0000',
        // disabled: styles.ttt,
        // hint: styles.tttt,
      },
      third: {
        main: '#002C83',
      },
      error: {
        main: '#000',
      },
      background: {
        default: '#fff',
      },
      grey: {
        800: '#27292b',
        850: '#1e1e27',
        900: '#1f2122',
      },
      red: {
        900: '#ed2624'
      },
      green: {
        900: '#266503'
      },
      blue: {
        900: '#9fdcef'
      }
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#b1afaf',
        },
      },
      MuiButton: {
        root: {
          borderRadius: 0,
        }, 
      },
      MuiLink: {
        underlineHover: {
          textDecoration: 'none !important'
        }
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            overflowX: 'hidden'
          },
        },
      }, 
    },
});

export default theme;