/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fade from '@material-ui/core/Fade';
import theme from "../theme/defaultTheme"
import Header from "../components/header/header"
import Footer from "../components/footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteBrandQuery {
      contentfulBrand {
        companyName
        phoneNumber
        logo {
          fixed (width: 165, height: 55, resizingBehavior: THUMB) {
            ...GatsbyContentfulFixed_withWebp
          }
          title       
        }
        email
        address
        #facebookUrl 
        #linkedinUrl
        #twitterUrl
        #googlePlusUrl
        #pinterestUrl
      }
      allContentfulMenu(sort: {order: ASC, fields: createdAt}) {
        edges {
          node {
            title
            slug
          }
        }
      }
      allContentfulAsset(filter: {title: {in: "create-project-video"}}) {
        edges {
          node {
            fluid (maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
            # description
          }	
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header data={data} />
      <Fade in>
        <main>{children}</main>
      </Fade>
      <Footer 
        data={data.contentfulBrand}
      />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
