import React from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import { Link } from "gatsby-theme-material-ui";
import Img from "gatsby-image"
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuIcon from '@material-ui/icons/Menu';
// import '../../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPhoneAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { 
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faGooglePlusG,
  faPinterestP
} from '@fortawesome/free-brands-svg-icons';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.tooltip
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  header: {
    background: "transparent"
  },
  headerBefore: {
    height: 40,
    backgroundColor: '#f0f0f0',
  },
  headerMenu: {
    height: 90
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column'
    }
  },
  toolBar: {
    minHeight: 40
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  socialIcons: {
    color: theme.palette.secondary.dark,
    marginLeft: 25
  },
  icons: {
    color: theme.palette.text.secondary
  },
  font: {
    fontFamily: 'open-sans, sans-serif',
  },
  activeLink: {
    '&:after': {
      content: "''",
      display: 'block',
      height: 3,
      // bottom: -8,
      width: '100%',
      background: '#fab702'
    }
  },
  uppercase: {
    textTransform: 'uppercase'
  }
}));

/* Scroll To Top Function Start */
function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

/* Scroll To Top Function End */

const Header = ({ data }, props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      display="flex"
      flexDirection="column"
      // alignItems="center"
      py={5}
    >
      <Img 
        fixed={data.contentfulBrand.logo.fixed} 
        alt={data.contentfulBrand.logo.title} 
      />
      {/* <Divider /> */}
      <List>
        {data.allContentfulMenu.edges.map(menu => (
          menu.node.slug === null
          ?
            <Link 
              to={"/"} key={menu.node.title} 
              color="textPrimary"
              underline="none"
            >
              <ListItem button >
                <ListItemText primary={menu.node.title} />
              </ListItem>
            </Link>
          :
            <Link 
              to={"/" + menu.node.slug} 
              key={menu.node.title} 
              color="textPrimary"
              underline="none"
            >
              <ListItem button >
                <ListItemText primary={menu.node.title} />
              </ListItem>
            </Link>
        ))}
      </List>
      <Divider />
      <Box
        display="flex"
        flexDirection="row"
        p={1}
      >
        {/* <Hidden xsDown> */}
          <FontAwesomeIcon 
            icon={faPhoneAlt} 
            className={classes.icons}
          />
          <Box pl={1}>
            <Typography 
              variant="body2"
              className={classes.font}
            >
              {data.contentfulBrand.phoneNumber}
            </Typography>
          </Box>
        {/* </Hidden> */}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        p={1}
      >
        <FontAwesomeIcon 
          icon={faEnvelope} 
          className={classes.icons} 
        />
        <Box pl={1}>
          <Typography 
            variant="body2"
            className={classes.font}
          >
            {data.contentfulBrand.email}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        py={3}
      >
        <FontAwesomeIcon 
          icon={faFacebookF} 
          className={classes.socialIcons}
        />
        <FontAwesomeIcon 
          icon={faLinkedinIn} 
          className={classes.socialIcons}
        />
        <FontAwesomeIcon 
          icon={faTwitter} 
          className={classes.socialIcons}
        />
        <FontAwesomeIcon 
          icon={faGooglePlusG} 
          className={classes.socialIcons}
        />
        <FontAwesomeIcon 
          icon={faPinterestP} 
          className={classes.socialIcons}
        />
      </Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar 
        position="fixed" 
        className={classes.header} 
        color="secondary" 
        elevation={0}
      >
        <Box 
          display="flex" 
          alignItems="center"
          className={classes.headerBefore}
        >
          <Container>
            <Box
              className={classes.menuContainer}
            >
              <Box
                display="flex"
                flexDirection="row"
              >
                <Hidden smDown>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    {/* <Hidden xsDown> */}
                      <FontAwesomeIcon 
                        icon={faPhoneAlt} 
                        className={classes.icons}
                      />
                      <Box pl={1}>
                        <Typography 
                          variant="body2"
                          color="textPrimary"
                          className={classes.font}
                        >
                          {data.contentfulBrand.phoneNumber}
                        </Typography>
                      </Box>
                    {/* </Hidden> */}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    ml={3}
                  >
                    <FontAwesomeIcon 
                      icon={faEnvelope} 
                      className={classes.icons} 
                    />
                    <Box pl={1}>
                      <Typography 
                        variant="body2"
                        color="textPrimary"
                        className={classes.font}
                      >
                        {data.contentfulBrand.email}
                      </Typography>
                    </Box>
                  </Box>
                </Hidden>
              </Box>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
              >
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Hidden smDown>
                  <FontAwesomeIcon 
                    icon={faFacebookF} 
                    className={classes.socialIcons}
                  />
                  <FontAwesomeIcon 
                    icon={faLinkedinIn} 
                    className={classes.socialIcons}
                  />
                  <FontAwesomeIcon 
                    icon={faTwitter} 
                    className={classes.socialIcons}
                  />
                  <FontAwesomeIcon 
                    icon={faGooglePlusG} 
                    className={classes.socialIcons}
                  />
                  <FontAwesomeIcon 
                    icon={faPinterestP} 
                    className={classes.socialIcons}
                  />
                </Hidden>
              </Box>
            </Box>
          </Container>
        </Box>
        <Container className={classes.headerMenu}>
          <Box 
            display="flex" 
            alignItems="center" 
            flexDirection="row" 
            justifyContent="space-between" 
            height="100%"
          >
            <Link to="/">
              <Img 
                fixed={data.contentfulBrand.logo.fixed} 
                alt={data.contentfulBrand.logo.title} 
              />
            </Link>
            <Hidden smDown>
              <Box 
                display="flex" 
                alignItems="center" 
                flexDirection="row" 
                justifyContent="space-around" 
                flexGrow="1" pl={27}
              >
                {
                  data.allContentfulMenu.edges.map(menu => (
                    menu.node.slug === null
                    ?
                      <Link 
                        to="/" 
                        key={menu.node.title} 
                        color="textPrimary" 
                        className={classes.uppercase}
                        activeClassName={classes.activeLink}
                      >
                        <Typography 
                          variant="subtitle2" 
                          component="p" 
                          color="textPrimary"
                          style={{fontFamily: 'open-sans, sans-serif', fontWeight: 'bold'}}
                        >
                          Home
                        </Typography>
                      </Link>
                    :
                      <Link 
                        to={"/" + menu.node.slug} 
                        key={menu.node.title} 
                        color="textPrimary" 
                        activeClassName={classes.activeLink}
                      >
                        <Typography 
                          variant="subtitle2" 
                          component="p" 
                          color="textPrimary"
                          className={classes.uppercase}
                          style={{fontFamily: 'open-sans, sans-serif', fontWeight: 'bold'}}
                        >
                          {menu.node.title}
                        </Typography>
                      </Link>
                  ))
                }
              </Box>
            </Hidden>
            <Link 
              to={"tel:" + data.contentfulBrand.phoneNumber} 
            >
              <Typography 
                variant="subtitle2" 
                component="p" 
                color="textSecondary"
                className={clsx(classes.icons, classes.uppercase)}
                style={{fontFamily: 'open-sans, sans-serif', fontWeight: 'bold'}}
              >
                Free Estimate
              </Typography>
            </Link>
          </Box>
        </Container>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Toolbar 
        id="back-to-top-anchor" 
        className={classes.toolBar}
      />
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  )
}

Header.propTypes = {
  // siteTitle: PropTypes.string,
  window: PropTypes.func,
}

Header.defaultProps = {
  // siteTitle: ``,
}

export default Header
